import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const ArrowUp: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m22.715 9.157-1.414 1.414-4.307-4.307V29.56h-2V6.27l-4.3 4.3L9.28 9.158l6.718-6.718 6.717 6.718Z"
      clipRule="evenodd"
    />
  </Icon>
);
